.filters-container {
    border: 1px solid #e6e5e1;
    border-radius: 12px;
    padding: 16px;
    margin-bottom: 16px;
    background-color: #FFF;
    height: auto;
    width: auto;
}

.filters-container * {
    background-color: #FFF;
}

.filters-container h4 {
    font-family: 'Museo Sans Rounded';
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 16px;
    background-color: #FFF;
}

.ac-inputs-inverters-filter-container {
    display: flex;
    font-family: 'Museo Sans Rounded';
    font-size: 14px;
    width: 50%;
    justify-content: space-between;
}

.filters-container h5 {
    font-family: 'Museo Sans Rounded';
    font-size: 14px;
    margin-bottom: 16px;
}

.inverters-filter-title-disabled {
    opacity: .5;
}
