.close-button-icon {
    padding: 0;
    border: none;
    background: none;
    background-color: #FAF9F6;
    margin-bottom: 12px;
}

.close-button-icon i {
    background-color: #FAF9F6;
}