.modal-container {
    background-color: #FAF9F6;
}

.phase-power-container {
    background-color: #FFF;
    border: 1px solid #e6e5e1;
    border-radius: 12px;
    padding: 12px 12px 16px 12px;
    margin-bottom: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

/* topo | direita | inferior | esquerda */
.phase-power-container p {
    background-color: #FFF;
    margin: 6px 0px 6px 0px;
    font-size: 14px;
}

.phase-power-container h3 {
    text-align: center;
    font-family: 'Museo Sans Rounded';
    font-size: 16px;
    background-color: #FFF;
    color: #003366;
    margin: 8px 0px 24px 0px;
}

.phase-inverter-warning {
    padding: 16px 0px 8px 0px;
    color: #003366;
    max-width: 600px;
    word-wrap: break-word;
    text-align: center;
}
