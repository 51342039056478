.loads-table-line-load {
    min-height: 32px;
}

.load-component td {
    border-bottom: 1px solid #e6e5e1;
}

.load-component button {
    font-family: 'Museo Sans Rounded';
    height: 24px;
    font-size: 8px;
    font-weight: bold;
}