.loads-table-container {
    background-color: #FFF;
    border: 1px solid #e6e5e1;
    border-radius: 12px;
    padding: 16px 12px 24px 12px;
    margin-bottom: 12px;
}

.loads-table-container th {
    font-weight: 600;
    border-bottom: 1px solid #e6e5e1;
}

.loads-table-container td {
    border-bottom: 1px solid #e6e5e1;
}


.loads-table {
    font-family: 'Museo Sans Rounded';
    font-size: 14px;
    width: 100%;
}

.loads-table * {
    background-color: #FFF;
}

.loads-table td {
    height: 28px;
}

.loads-table-col-name {
    height: 32px;
    width: 40%;
}

.phase-title {
    text-align: center;
    font-family: 'Museo Sans Rounded';
    font-size: 16px;
    background-color: #FFF;
    color: #003366;
    margin: 8px 0px 32px 0px;
}

.phase-power-title {
    font-family: 'Museo Sans Rounded';
    font-size: 14px;
    background-color: #FFF;
    margin: 32px 0px 8px 0px;
    color: #003366;
}
