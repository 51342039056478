.form-input-container {
    width: 45%;
    height: 72px;
    margin-top: 20px;
    font-family: 'Museo Sans Rounded';
    font-size: 16px;
}

.form-input {
    margin-top: 8px;
    height: 40px;
    padding: 6px 12px;
}

@media all and (max-width: 768px) {
    .form-input-container {
        width: 100%;
    }
    /* .div-double-input input {
        width: 100%;
    } */
}
