.inverter-list-container {
    display: flex;
    flex-direction: column;
}

.no-inverter-suggested-advice {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.inverter-list-container h4 {
    font-family: 'Museo Sans Rounded';
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 12px;
    width: auto;
    background-color: #FAF9F6;
}

.inverter-list-container p {
    margin-bottom: 36px;
    max-width: 400px;
    text-align: center;
    font-size: 14px;
    font-family: 'Museo Sans Rounded';
}

.title-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
}

@media all and (max-width: 768px) {
    .inverter-list-container {
        margin-top: 24px;
    }
}
