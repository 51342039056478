* {
    background-color: #FAF9F6;
}

.form-input-container {
    width: 100%;
    height: 72px;
    margin-top: 20px;
    font-family: 'Museo Sans Rounded';
    font-size: 16px;
}

.form-input-container-medium {
    width: 45%;
    height: 72px;
    margin-top: 20px;
    font-family: 'Museo Sans Rounded';
    font-size: 16px;
}

.form-input {
    margin-top: 8px;
    height: 40px;
}

.form-input-container #hsp-input {
    width: 45%;
}

@media all and (max-width: 768px) {
    .form-input-container {
        width: 100%;
    }
    .form-input-container-medium {
        width: 100%;
    }
}
