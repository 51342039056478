.results-list-container * {
    background-color: #FFF;
}

.results-list-container h4 {
    font-family: 'Museo Sans Rounded';
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 16px;
}

.results-list-container {
    border: 1px solid #e6e5e1;
    border-radius: 12px;
    padding: 16px;
    background-color: #FFF;
    height: auto;
    width: 100%;
    margin-bottom: 12px;
}

.results-list-container div {
    font-family: 'Museo Sans Rounded';
    font-size: 14px;
    margin-bottom: 16px;
}

.results-table {
    font-family: 'Museo Sans Rounded';
    font-size: 14px;
    width: 100%;
    /* color: rgb(0, 51, 102); */
    /* border: 1px solid black; */
    height: auto;
}

.results-table button {
    height: 38px;
    padding: 0;
    border: none;
    background: none;
    color: #212529,
}