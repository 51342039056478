.modal-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Museo Sans Rounded';
}

.modal-container h4 {
    font-size: 24px;
    margin-bottom: 16px;
}

.modal-container p {
    font-size: 16px;
    text-align: center;
}

.buttons-container {
    width: 100%;
    display: flex;
    justify-content: center;
}

.buttons-container button {
    margin: 0px 12px 0px 12px;
}