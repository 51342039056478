.inverter-card-container {
    display: flex;
    justify-content: center;
    background-color: #FFF;
    border: 1px solid #e6e5e1;
    border-radius: 12px;
    padding: 12px;
    margin-bottom: 16px;
    font-size: 12px;
    width: auto;
}

.inverter-card-img-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

/* .inverter-card-container h5 {
    font-family: 'Museo Sans Rounded';
    font-size: 16px;
} */

.inverter-card-container * {
    background-color: #FFF;
}

.inverter-card-container h5 {
    background-color: #FFF;
    font-family: 'Museo Sans Rounded';
    font-size: 14px;
    margin-bottom: 16px;
}

.inverter-card-container h6 {
    background-color: #FFF;
    font-family: 'Museo Sans Rounded';
    font-size: 12px;
    margin-bottom: 16px;
}

.inverter-image {
    /* max-width: 150px; */
}

.inverter-card-params {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 12px;
    align-items: flex-start;
    width: auto;
}

.buttons-container {
    display: flex;
}

@media all and (max-width: 600px) {
    .buttons-container {
        flex-direction: column;
    }
}

@media all and (max-width: 450px) {
    .inverter-image {
        max-width: 100px;
    }
    .inverter-card-container {
        font-size: 10px;
    }
    .inverter-card-container h5 {
        font-size: 12px;
    }
    .inverter-card-container h6 {
        font-size: 10px;
    }
}
