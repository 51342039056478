/* topo | direita | inferior | esquerda */

.div-data {
    margin: 14px 24px 16px 24px;
    width: 90%;
}

.div-data input {
    /* width: 100%; */
}

/* .div-data input select {
    width: 50%;
} */


.div-data h3 {
    font-family: 'Museo Sans Rounded';
    font-size: 24px;
    padding: 16px 0 4px 0;
    color: #114579;
}

.div-double-input {
    margin-top: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.div-single-input {
    display: flex;
    justify-content: center;
}

.btn-reset {
    padding: 0;
    border: none;
    background: none;
    color: #dc3545;
    /* text-decoration: underline; */
}

.title-container {
    display: flex;
    justify-content: space-between;
}

@media all and (max-width: 1150px) {
    .div-double-input {
        flex-direction: column;
    }
    .div-double-input * {
        width: 100%;
    }
    /* .div-double-input input {
        width: 100%;
    } */
}