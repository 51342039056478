.result-line {
    height: 40px;
    width: auto;
}

.result-info-line button {
    height: 40px;
    width: auto;
    font-family: 'Museo Sans Rounded';
}

.modal-container {
    background-color: #FAF9F6;
}

.result-name {
    padding-right: 32px;
}
