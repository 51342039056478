.form {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 100%;
    margin-bottom: 12px;
}

.section-data {
    width: 45%;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 960px;
    border: 1px solid #e6e5e1;
    border-radius: 12px;
    margin-top: 12px;
}

.div-button {
    margin: 24px;
    width: 100%;
    display: flex;
    justify-content: center;
}

@media all and (max-width: 1280px) {
    .section-data * {
        font-size: 14px;
    }
}

@media all and (max-width: 1150px) {
    .section-data {
        min-height: 1221px;
    }
}

@media all and (max-width: 900px) {
    .section-data {
        width: 95%;
    }
    .form {
        flex-direction: column;
    }
}

