.victron-advice {
    text-align: center;
    margin-top: 12px;
    color: #dc3545;
    font-family: 'Museo Sans Rounded';
}

.signature {
    font-size: 12px;
    text-align: center;
    margin-top: 24px;
    font-family: 'Museo Sans Rounded';
}

.version {
    font-size: 12px;
    text-align: center;
    font-family: 'Museo Sans Rounded';
}