.power-advice {
    margin-top: 16px;
    margin-bottom: 0px;
    padding: 0px;
    display: flex;
    align-items: center;
    color: #DB0F27;
}

.power-advice h5 {
    font-size: 14px;
    margin: 0px;
    padding: 0px;
}

.power-advice i {
    font-size: 14px;
    margin: 0px 4px 0px 0px;
    padding: 0px 0px 0px 0px;
    /* color: #028A0F; */
    vertical-align: middle;
}
