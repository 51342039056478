/* topo | direita | inferior | esquerda */
.div-results {
    margin: 14px 24px 0px 24px;
    width: auto;
}

.div-results h3 {
    font-family: 'Museo Sans Rounded';
    font-size: 24px;
    padding: 4px 0 4px 0;
    color: #114579;
}

.results-container {
    display: flex;
}

.buttons-container {
    display: flex;
}

/* .div-button {
    margin: 16px 0px 0px 24px;
    width: auto;
    display: flex;
} */

@media all and (max-width: 768px) {
    .results-container {
        flex-direction: column;
    }
}
