.header-container * {
    background-color: #f3f2f0c9;
}

.nav {
    width: 100%;
}

.select-language {
    border: 0px;
    right: 12px;
    position: absolute;
}

.header-container {
    width: 100%;
    padding: 5%;
    padding-top: 16px;
    padding-bottom: 16px;
    display: flex;
    align-items: center;
    background-color: #f3f2f0c9;
    border-bottom: 1px solid #e6e5e1;
    margin-bottom: 32px;
    box-shadow: 0 0.1rem 0.2rem rgba(126, 125, 125, 0.15);
}

.header-container-first-line {
    display: flex;
}

.header-container-second-line {
    width: 100%;
}

.header-container-first-line nav {
    display: flex;
    align-items: center;
}

.header-container i {
    background-color: #f3f2f0c9;
}

.header-container nav a {
    font-family: 'Museo Sans Rounded';
    font-size: 16px;
    padding: 4px 0 4px 0;
    color: #114579;
    margin-right: 24px;
    text-decoration: none;
}

.nav-option:hover {
    color: rgb(250, 129, 40);
}

.header-container img {
    width: 221px;
    margin-right: 120px;
}

.nav-bar-button {
    padding: 0;
    border: none;
    background: none;
    background-color: #f3f2f0c9;
    visibility: hidden;
    display: none;
}

@media all and (max-width: 900px) {
    .header-container {
        flex-direction: column;
        margin: 0px;
    }
    .header-container img {
        margin-right: 0px;
    }
    .header-container-first-line {
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }
    .header-container-second-line {
        justify-content: center;
        width: 100%;
    }
    .nav-bar-button {
        visibility: visible;
        display: block;
    }
    .nav {
        display: flex;
        justify-content: center;
        margin-top: 16px;
        width: 100%;
        flex-direction: column;
        align-items: center;
    }
    .header-container nav a {
        margin: 0px 12px 0px 12px;
    }
    .select-language {
        border: 0px;
        position: static;
        margin-top: 8px;
    }
}