.div-data {
    width: 90%;
    padding: 0px 0 4px 0;
}

.div-data h3 {
    font-family: 'Museo Sans Rounded';
    font-size: 24px;
    padding: 16px 0 4px 0;
    margin: 14px 24px 16px 0px;
    color: #114579;
}

.div-double-input {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 0 4px 0;
}

.div-button {
    margin: 24px;
}

.load-warning-container {
    width: 90%;
    font-family: 'Museo Sans Rounded';
    font-size: 12px;
    color: #dc3545;
    margin-bottom: 12px;
}

.table-wrapper {
    max-height: 437px;
    overflow-y: scroll;
    width: 90%;
    background-color: white;
    border: 1px solid #e6e5e1;
    padding: 0px 12px 12px 12px;
}

.table-wrapper * {
    background-color: white;
}

@media all and (max-width: 768px) {
    .div-double-input {
        flex-direction: column;
    }
}

@media all and (max-width: 1150px) {
    .table-wrapper  {
        max-height: 684px;
    }
}
