.inverter-container {
    background-color: #FAF9F6;
}

.modal-container {
    height: 40%;
    width: auto;
    background-color: #FAF9F6;
}

.modal-container h4 {
    font-family: 'Museo Sans Rounded';
    font-size: 14px;
    color: #64635f;
    margin-bottom: 12px;
}
