.page-results {
    display: flex;
    height: auto;
    justify-content: center;
    /* align-items: center; */
    margin-top: 12px;
}
/* topo | direita | inferior | esquerda */
.section-results {
    width: auto;
    display: flex;
    flex-direction: column;
    height: auto;
    border: 1px solid #e6e5e1;
    border-radius: 12px;
    padding-bottom: 12px;
    margin: 0px 12px 12px 12px;
}

@media all and (max-width: 375px) {
    .section-results {
        width: 90%;
    }
}