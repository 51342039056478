.no-loads-advice {
    font-size: 12px;
    font-family: 'Museo Sans Rounded';
    color: #dc3545;
}

.loads-table {
    font-family: 'Museo Sans Rounded';
    font-size: 14px;
    width: 100%;
}

.loads-table th {
    font-weight: 600;
    border-bottom: 1px solid #e6e5e1;
    /* border-top: 1px solid #e6e5e1; */
}

.loads-table-col-name {
    min-width: 40%;
    height: 32px;
}

.loads-table th {
    position: sticky;
    top: 0px
}